import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import Error404_1 from "../assets/Error404_1";
import Error404_2 from "../assets/Error404_2";
import "../styles/error.css";
import { AiChatBotShortLogo } from "../assets/AiBotAssets";
import { OmniLogoWithText } from "../assets/OmniAssets";

import environment from "./../constants/Environment";

function Error404() {
  const route = useNavigate();
  const [toggle, setToggle] = useState<any>(false);

  return (
    <div className="error_page">
      <div className="omni-logo">
        {environment().isAI ? <AiChatBotShortLogo /> : <OmniLogoWithText />}

        <h1>{environment().isAI ? "Chatbot Builder" : "Omniengage"}</h1>
      </div>
      <div className="er-left">
        <Error404_1 />
        <h1>Woah, you seem to have hit a dead end.</h1>
        <span>That's okay though, you can always head back home.</span>
        <button
          onClick={() => {
            route("/dashboard");
          }}
        >
          Back to home
        </button>
      </div>
      <div className="er-right">
        {toggle ? (
          <iframe
            src="https://live.omniengage.co/nft-omni-95"
            height="100%"
            width="100%"
            style={{
              border: "none",
              overflowX: "hidden",
            }}
          />
        ) : (
          <Fragment>
            <Error404_2 />
            <div className="err-right-span">
              <span className="sp1">Didn’t find what you were looking for?</span>
              <span>We might have something better!</span>
            </div>
            <button onClick={() => setToggle(true)}>Show me what you got</button>
          </Fragment>
        )}
      </div>
    </div>
  );
}
export default Error404;
