const getConfig = () => {
  let environment = window.location.hostname.split(".")[2] ?? "localhost";

  switch (environment) {
    case "localhost":
      if (window.location.hostname.startsWith("ai.")) {
        return {
          SERVER_API_URL: "http://localhost:4000",
          // http://localhost:4000
          LIVEBOT_URL: "http://localhost:3001",
          PUBLIC_DOMAIN: "ai.localhost",
          PAGE_DOMAIN: "ai.localhost",
          PUBLIC_GOOGLE_APP_ID:
            "471909029023-13g22jeu5vbaosvrluu2nhe884aohf2b.apps.googleusercontent.com",
          PUBLIC_FACEBOOK_APP_ID: 2106755656362298,
          FACEBOOK_PAGE_ACCESS_TOKEN:
            "EAAQNduYKoLIBO9ZABP9XnZBfgXQZAPpg8jHOUxvzkXqZBzNTPMRcIX9IABSusOzUcnPsrQOach1JYWgYaZBBLbm4muFwVND0Ih6TBDgXZCtcbGkF29fdzk9k9aiHofRyYEAhs88fTGpf2lduWA8eqlN1ViJWu6exh0ZBxJM5ZCYr9IYLT7qt1JtxwUkptb59IO2an8RFzV42RPFqkGrEmpLe9JN43FUqf5HM9eEEEF7vxBkZD",
          FILESTACK_API_KEY: "AYTVLaHLRSs6sCRK6mNFcz", //local jp API key
          TIMEKIT_API_KEY: "test_widget_key_fjjvV6bPF8uCze6j3qJGMfbmBemvdPKf",
          TIMEKIT_PROJECT_ID: "d319cfec-7618-4765-abf5-fac8c9381192",
          isAI: true,
          META_API_VERSION: "v19.0",
          META_CONFIGS: {
            login: "883317163601799",
            pages: "1528923587675165", // FB pages
            whatsapp: "2357118394498759", // WA business account
          },
          RECAPTCHAKEY: "6Lcj1DMqAAAAAAn7Y6wSnsVzu2Uq_cjvu_CY84Zs",
          OUTLOOK_CLIENT_ID: "979c9342-67f3-4831-94f9-877f8013b90a",
          ENVIRONMENT: "development",
          STRIPE_PUBLIC_KEY:
            "pk_test_51LMK5oFas3GhAJRiv9K19NjmkoXHIXgzGdTuK4XSkKRFxJrKklXiKJ65tg2s3MXIEBbPnjE3K131pNLXcj39pxLR00qA7RU3Zk",
          MONTHLYPLAN: "price_1PXd6cFas3GhAJRiDsUXqkT8",
          YEARLYPLAN: "price_1PXd9CFas3GhAJRiGFGi1mSU",
          MONTHLYPLAN_PROFESSIONAL: "price_1PXd7jFas3GhAJRiF111LQdI",
          YEARLYPLAN_PROFESSIONAL: "price_1PXd9zFas3GhAJRi2r3S0UHw",
          TRIALPLAN: "price_1PXd5LFas3GhAJRi3AtOqvUS",
          LIFETIMEPLAN1: "price_1Prdz1AMQsTGGiHofHPyHq8O",
          LIFETIMEPLAN2: "price_1PreHcAMQsTGGiHouvvXYpmV",
          SALESFORCE_CLIENT_ID:
            "3MVG9NnK0U_HimV7JrqZ8dW4pAfyPAtkdONq6kD37dmcka6VuntG9EqaJp3EJK0fLqDi9PNDQ4eA6btVUArUc",
          SALESFORCE_CLIENT_SECRET:
            "5671B0B7D4E76CA1613507CE5C690C875A902A47118C5BEB283840255BD0B255",
          SALESFORCE_AUTH_URL:
            "https://login.salesforce.com/services/oauth2/authorize",
        };
      } else {
        return {
          SERVER_API_URL: "http://localhost:4000",
          LIVEBOT_URL: "http://localhost:3001",
          PUBLIC_DOMAIN: "localhost",
          PAGE_DOMAIN: "localhost",
          PUBLIC_GOOGLE_APP_ID:
            "471909029023-13g22jeu5vbaosvrluu2nhe884aohf2b.apps.googleusercontent.com",
          PUBLIC_FACEBOOK_APP_ID: 2106755656362298,
          FACEBOOK_PAGE_ACCESS_TOKEN:
            "EAAQNduYKoLIBO9ZABP9XnZBfgXQZAPpg8jHOUxvzkXqZBzNTPMRcIX9IABSusOzUcnPsrQOach1JYWgYaZBBLbm4muFwVND0Ih6TBDgXZCtcbGkF29fdzk9k9aiHofRyYEAhs88fTGpf2lduWA8eqlN1ViJWu6exh0ZBxJM5ZCYr9IYLT7qt1JtxwUkptb59IO2an8RFzV42RPFqkGrEmpLe9JN43FUqf5HM9eEEEF7vxBkZD",
          FILESTACK_API_KEY: "AYTVLaHLRSs6sCRK6mNFcz",
          TIMEKIT_API_KEY: "test_widget_key_fjjvV6bPF8uCze6j3qJGMfbmBemvdPKf",
          TIMEKIT_PROJECT_ID: "d319cfec-7618-4765-abf5-fac8c9381192",
          isAI: false,
          META_API_VERSION: "v19.0",
          STRIPE_PUBLIC_KEY:
            "pk_test_51LDGaqAMQsTGGiHorKcAtUMskM5BOr2ycwMnLkpkOA2OprA618Q9ifCzblBI5bHzDymRuTQkmMDBGN09uKuFrCQs00p2zXDibJ",
          META_CONFIGS: {
            login: "883317163601799",
            pages: "1528923587675165", // FB pages
            whatsapp: "2357118394498759", // WA business account
          },
          RECAPTCHAKEY: "6Lcj1DMqAAAAAAn7Y6wSnsVzu2Uq_cjvu_CY84Zs",
          OUTLOOK_CLIENT_ID: "979c9342-67f3-4831-94f9-877f8013b90a",
          ENVIRONMENT: "development",
          MONTHLYPLAN: "price_1PUNgrAMQsTGGiHo3P1Nmb3A",
          YEARLYPLAN: "price_1PUNjlAMQsTGGiHomUfLjniP",
          TRIALPLAN: "price_1PUNnQAMQsTGGiHockGBkgWA",
          LIFETIMEPLAN1: "price_1Prdz1AMQsTGGiHofHPyHq8O",
          LIFETIMEPLAN2: "price_1PreHcAMQsTGGiHouvvXYpmV",
          SALESFORCE_CLIENT_ID:
            "3MVG9NnK0U_HimV7JrqZ8dW4pAfyPAtkdONq6kD37dmcka6VuntG9EqaJp3EJK0fLqDi9PNDQ4eA6btVUArUc",

          SALESFORCE_CLIENT_SECRET:
            "5671B0B7D4E76CA1613507CE5C690C875A902A47118C5BEB283840255BD0B255",
          SALESFORCE_AUTH_URL:
            "https://login.salesforce.com/services/oauth2/authorize",
        };
      }

    case "ailocal":
      return {
        SERVER_API_URL: "http://localhost:4000",
        PUBLIC_GOOGLE_APP_ID:
          "717509625034-05qjj1o7ulsr5rd5s0vbqgutg4752b5s.apps.googleusercontent.com",
        PUBLIC_FACEBOOK_APP_ID: 2106755656362298,
        FACEBOOK_PAGE_ACCESS_TOKEN:
          "EAAQNduYKoLIBO9ZABP9XnZBfgXQZAPpg8jHOUxvzkXqZBzNTPMRcIX9IABSusOzUcnPsrQOach1JYWgYaZBBLbm4muFwVND0Ih6TBDgXZCtcbGkF29fdzk9k9aiHofRyYEAhs88fTGpf2lduWA8eqlN1ViJWu6exh0ZBxJM5ZCYr9IYLT7qt1JtxwUkptb59IO2an8RFzV42RPFqkGrEmpLe9JN43FUqf5HM9eEEEF7vxBkZD",
        FILESTACK_API_KEY: "AYTVLaHLRSs6sCRK6mNFcz",
        PUBLIC_DOMAIN: "app.chatbotbuilder.ailocal",
        PAGE_DOMAIN: "chatbotbuilder.ailocal",
        LIVEBOT_URL: "http://localhost:3001",
        TIMEKIT_API_KEY: "test_widget_key_fjjvV6bPF8uCze6j3qJGMfbmBemvdPKf",
        TIMEKIT_PROJECT_ID: "83d6c352-cd2a-4c84-8efe-12667c2a4d29",
        isAI: true,
        META_API_VERSION: "v19.0",
        OUTLOOK_CLIENT_ID: "979c9342-67f3-4831-94f9-877f8013b90a",
        ENVIRONMENT: "development",
        SALESFORCE_CLIENT_ID:
          "3MVG9NnK0U_HimV7JrqZ8dW4pAfyPAtkdONq6kD37dmcka6VuntG9EqaJp3EJK0fLqDi9PNDQ4eA6btVUArUc",
        SALESFORCE_CLIENT_SECRET:
          "5671B0B7D4E76CA1613507CE5C690C875A902A47118C5BEB283840255BD0B255",
      };
    case "local":
      return {
        SERVER_API_URL: "http://localhost:4000",
        PUBLIC_GOOGLE_APP_ID:
          "717509625034-05qjj1o7ulsr5rd5s0vbqgutg4752b5s.apps.googleusercontent.com",
        PUBLIC_FACEBOOK_APP_ID: 2106755656362298,
        FACEBOOK_PAGE_ACCESS_TOKEN:
          "EAAQNduYKoLIBO9ZABP9XnZBfgXQZAPpg8jHOUxvzkXqZBzNTPMRcIX9IABSusOzUcnPsrQOach1JYWgYaZBBLbm4muFwVND0Ih6TBDgXZCtcbGkF29fdzk9k9aiHofRyYEAhs88fTGpf2lduWA8eqlN1ViJWu6exh0ZBxJM5ZCYr9IYLT7qt1JtxwUkptb59IO2an8RFzV42RPFqkGrEmpLe9JN43FUqf5HM9eEEEF7vxBkZD",
        FILESTACK_API_KEY: "AYTVLaHLRSs6sCRK6mNFcz",
        PUBLIC_DOMAIN: "app.engage.local",
        LIVEBOT_URL: "http://localhost:3001",
        PAGE_DOMAIN: "engage.local",
        TIMEKIT_API_KEY: "test_widget_key_fjjvV6bPF8uCze6j3qJGMfbmBemvdPKf",
        TIMEKIT_PROJECT_ID: "d319cfec-7618-4765-abf5-fac8c9381192",
        isAI: false,
        META_API_VERSION: "v19.0",
        OUTLOOK_CLIENT_ID: "979c9342-67f3-4831-94f9-877f8013b90a",
        ENVIRONMENT: "development",
        RECAPTCHAKEY: "6Lcj1DMqAAAAAAn7Y6wSnsVzu2Uq_cjvu_CY84Zs",
        STRIPE_PUBLIC_KEY:
          "pk_test_51LDGaqAMQsTGGiHorKcAtUMskM5BOr2ycwMnLkpkOA2OprA618Q9ifCzblBI5bHzDymRuTQkmMDBGN09uKuFrCQs00p2zXDibJ",
        MONTHLYPLAN: "price_1PUNgrAMQsTGGiHo3P1Nmb3A",
        YEARLYPLAN: "price_1PUNjlAMQsTGGiHomUfLjniP",
        TRIALPLAN: "price_1PUNnQAMQsTGGiHockGBkgWA",
        MONTHLYBRANDING: "price_1PUNvvAMQsTGGiHojYj1anlG",
        YEARLYBRANDING: "price_1PUNutAMQsTGGiHoxYfwJxBY",
        SALESFORCE_CLIENT_ID:
          "3MVG9NnK0U_HimV7JrqZ8dW4pAfyPAtkdONq6kD37dmcka6VuntG9EqaJp3EJK0fLqDi9PNDQ4eA6btVUArUc",
        SALESFORCE_CLIENT_SECRET:
          "5671B0B7D4E76CA1613507CE5C690C875A902A47118C5BEB283840255BD0B255",
      };
    case "chat":
      if (window.location.hostname.split(".")[0] === "ai")
        return {
          SERVER_API_URL: "https://api.outgrow.chat",
          PUBLIC_GOOGLE_APP_ID:
            "825285028982-ejjvllvr0aid7dnit7ca9aib01b25slt.apps.googleusercontent.com",
          PUBLIC_FACEBOOK_APP_ID: 2106755656362298,
          RECAPTCHAKEY: "6Lcj1DMqAAAAAAn7Y6wSnsVzu2Uq_cjvu_CY84Zs",
          FILESTACK_API_KEY: "A3ygIw4hISSCdApqW4SAwz",
          PUBLIC_DOMAIN: "ai.outgrow.chat",
          PAGE_DOMAIN: "ai.outgrow.chat",
          LIVEBOT_URL: "https://liveai.outgrow.chat",
          TIMEKIT_API_KEY: "test_widget_key_fjjvV6bPF8uCze6j3qJGMfbmBemvdPKf",
          TIMEKIT_PROJECT_ID: "83d6c352-cd2a-4c84-8efe-12667c2a4d29",
          isAI: true,
          META_API_VERSION: "v19.0",
          OUTLOOK_CLIENT_ID: "979c9342-67f3-4831-94f9-877f8013b90a",
          ENVIRONMENT: "staging",
          STRIPE_PUBLIC_KEY:
            "pk_test_51LMK5oFas3GhAJRiv9K19NjmkoXHIXgzGdTuK4XSkKRFxJrKklXiKJ65tg2s3MXIEBbPnjE3K131pNLXcj39pxLR00qA7RU3Zk",
          MONTHLYPLAN: "price_1PXd6cFas3GhAJRiDsUXqkT8",
          YEARLYPLAN: "price_1PXd9CFas3GhAJRiGFGi1mSU",
          MONTHLYPLAN_PROFESSIONAL: "price_1PXd7jFas3GhAJRiF111LQdI",
          YEARLYPLAN_PROFESSIONAL: "price_1PXd9zFas3GhAJRi2r3S0UHw",
          TRIALPLAN: "price_1PXd5LFas3GhAJRi3AtOqvUS",
          LIFETIMEPLAN1: "price_1Prdz1AMQsTGGiHofHPyHq8O",
          LIFETIMEPLAN2: "price_1PreHcAMQsTGGiHouvvXYpmV",
          SALESFORCE_CLIENT_ID:
            "3MVG9NnK0U_HimV7JrqZ8dW4pAfyPAtkdONq6kD37dmcka6VuntG9EqaJp3EJK0fLqDi9PNDQ4eA6btVUArUc",
          SALESFORCE_CLIENT_SECRET:
            "5671B0B7D4E76CA1613507CE5C690C875A902A47118C5BEB283840255BD0B255",
          SALESFORCE_AUTH_URL:
            "https://login.salesforce.com/services/oauth2/authorize",
        };
      else
        return {
          SERVER_API_URL: "https://api.outgrow.chat",
          PUBLIC_GOOGLE_APP_ID:
            "825285028982-ejjvllvr0aid7dnit7ca9aib01b25slt.apps.googleusercontent.com",
          PUBLIC_FACEBOOK_APP_ID: 2106755656362298,
          FACEBOOK_PAGE_ACCESS_TOKEN:
            "EAAQNduYKoLIBO9ZABP9XnZBfgXQZAPpg8jHOUxvzkXqZBzNTPMRcIX9IABSusOzUcnPsrQOach1JYWgYaZBBLbm4muFwVND0Ih6TBDgXZCtcbGkF29fdzk9k9aiHofRyYEAhs88fTGpf2lduWA8eqlN1ViJWu6exh0ZBxJM5ZCYr9IYLT7qt1JtxwUkptb59IO2an8RFzV42RPFqkGrEmpLe9JN43FUqf5HM9eEEEF7vxBkZD",
          FILESTACK_API_KEY: "A3ygIw4hISSCdApqW4SAwz",
          PUBLIC_DOMAIN: "app.outgrow.chat",
          PAGE_DOMAIN: "omniengage.co",
          LIVEBOT_URL: "https://live.outgrow.chat",
          TIMEKIT_API_KEY: "test_widget_key_fjjvV6bPF8uCze6j3qJGMfbmBemvdPKf",
          TIMEKIT_PROJECT_ID: "d319cfec-7618-4765-abf5-fac8c9381192",
          isAI: false,
          META_API_VERSION: "v19.0",
          OUTLOOK_CLIENT_ID: "979c9342-67f3-4831-94f9-877f8013b90a",
          ENVIRONMENT: "staging",
          RECAPTCHAKEY: "6Lcj1DMqAAAAAAn7Y6wSnsVzu2Uq_cjvu_CY84Zs",
          STRIPE_PUBLIC_KEY:
            "pk_test_51LDGaqAMQsTGGiHorKcAtUMskM5BOr2ycwMnLkpkOA2OprA618Q9ifCzblBI5bHzDymRuTQkmMDBGN09uKuFrCQs00p2zXDibJ",
          MONTHLYPLAN: "price_1PUNgrAMQsTGGiHo3P1Nmb3A",
          YEARLYPLAN: "price_1PUNjlAMQsTGGiHomUfLjniP",
          LIFETIMEPLAN1: "price_1Prdz1AMQsTGGiHofHPyHq8O",
          LIFETIMEPLAN2: "price_1PreHcAMQsTGGiHouvvXYpmV",
          TRIALPLAN: "price_1PUNnQAMQsTGGiHockGBkgWA",
          SALESFORCE_CLIENT_ID:
            "3MVG9NnK0U_HimV7JrqZ8dW4pAfyPAtkdONq6kD37dmcka6VuntG9EqaJp3EJK0fLqDi9PNDQ4eA6btVUArUc",
          SALESFORCE_CLIENT_SECRET:
            "5671B0B7D4E76CA1613507CE5C690C875A902A47118C5BEB283840255BD0B255",
          SALESFORCE_AUTH_URL:
            "https://login.salesforce.com/services/oauth2/authorize",
        };
    case "co":
      return {
        SERVER_API_URL: "https://api.omniengage.co",
        PUBLIC_GOOGLE_APP_ID:
          "717509625034-05qjj1o7ulsr5rd5s0vbqgutg4752b5s.apps.googleusercontent.com",
        PUBLIC_FACEBOOK_APP_ID: 2106755656362298,
        FACEBOOK_PAGE_ACCESS_TOKEN:
          "EAAQNduYKoLIBO9ZABP9XnZBfgXQZAPpg8jHOUxvzkXqZBzNTPMRcIX9IABSusOzUcnPsrQOach1JYWgYaZBBLbm4muFwVND0Ih6TBDgXZCtcbGkF29fdzk9k9aiHofRyYEAhs88fTGpf2lduWA8eqlN1ViJWu6exh0ZBxJM5ZCYr9IYLT7qt1JtxwUkptb59IO2an8RFzV42RPFqkGrEmpLe9JN43FUqf5HM9eEEEF7vxBkZD",
        FILESTACK_API_KEY: "A3ygIw4hISSCdApqW4SAwz",
        RECAPTCHAKEY:"6Lf4NDgqAAAAALbxk3ZhEjoYkNvEswfuIQaq4eX1",
        PUBLIC_DOMAIN: "app.omniengage.co",
        PAGE_DOMAIN: "omniengage.co",
        LIVEBOT_URL: "https://<subDomain>.omniengage.co",
        TIMEKIT_API_KEY: "live_widget_key_XLsFIuOL345Gwtef0OQRcMYeNfjqAsYe", // Outgrow
        TIMEKIT_PROJECT_ID: "a0aa96e2-d35c-476b-95ed-790c648e8453", // omni
        isAI: false,
        META_API_VERSION: "v19.0",
        OUTLOOK_CLIENT_ID: "979c9342-67f3-4831-94f9-877f8013b90a",
        ENVIRONMENT: "production",
        LIFETIMEPLAN1: "price_1PuYvoAMQsTGGiHopqQP8rbA",
        LIFETIMEPLAN2: "price_1PuYvrAMQsTGGiHoqHEC62Rf",
        MONTHLYPLAN: "price_1PUO77AMQsTGGiHo9aJ4kKk9",
        YEARLYPLAN: "price_1PUO8IAMQsTGGiHoci3WY5NV",
        TRIALPLAN: "price_1PUO9WAMQsTGGiHoHFwmjojN",
        STRIPE_PUBLIC_KEY:
          "pk_live_51LDGaqAMQsTGGiHoxsGviZI53uKvzgMa6cinvyozi9JSVzVqLIuWpo5RqlSIzukKjUTRwJP5LOnGBwjenK7IGRxT00L6zOJlHO",
        SALESFORCE_CLIENT_ID:
          "3MVG9NnK0U_HimV7JrqZ8dW4pAfyPAtkdONq6kD37dmcka6VuntG9EqaJp3EJK0fLqDi9PNDQ4eA6btVUArUc",
        SALESFORCE_CLIENT_SECRET:
          "5671B0B7D4E76CA1613507CE5C690C875A902A47118C5BEB283840255BD0B255",
      };
    case "net":
      return {
        SERVER_API_URL: "https://api.chatbotbuilder.net",
        PUBLIC_GOOGLE_APP_ID:
          "717509625034-05qjj1o7ulsr5rd5s0vbqgutg4752b5s.apps.googleusercontent.com",
        PUBLIC_FACEBOOK_APP_ID: 2106755656362298,
        FACEBOOK_PAGE_ACCESS_TOKEN:
          "EAAQNduYKoLIBO9ZABP9XnZBfgXQZAPpg8jHOUxvzkXqZBzNTPMRcIX9IABSusOzUcnPsrQOach1JYWgYaZBBLbm4muFwVND0Ih6TBDgXZCtcbGkF29fdzk9k9aiHofRyYEAhs88fTGpf2lduWA8eqlN1ViJWu6exh0ZBxJM5ZCYr9IYLT7qt1JtxwUkptb59IO2an8RFzV42RPFqkGrEmpLe9JN43FUqf5HM9eEEEF7vxBkZD",
        FILESTACK_API_KEY: "A3ygIw4hISSCdApqW4SAwz",
        PUBLIC_DOMAIN: "app.chatbotbuilder.net",
        RECAPTCHAKEY:"6Lf4NDgqAAAAALbxk3ZhEjoYkNvEswfuIQaq4eX1",
        PAGE_DOMAIN: "chatbotbuilder.net",
        LIVEBOT_URL: "https://<subDomain>.chatbotbuilder.net",
        TIMEKIT_API_KEY: "live_widget_key_XLsFIuOL345Gwtef0OQRcMYeNfjqAsYe", // Outgrow
        TIMEKIT_PROJECT_ID: "c7fa7704-7a34-4bc6-824f-9b2a2af62630", // chatBot
        isAI: true,
        META_API_VERSION: "v19.0",
        OUTLOOK_CLIENT_ID: "979c9342-67f3-4831-94f9-877f8013b90a",
        ENVIRONMENT: "production",
        STRIPE_PUBLIC_KEY:
          "pk_live_51LMK5oFas3GhAJRiwyHa2yRixlsElEgVtTdzEHJewj5IsXqzXs9H2G4XFVlgOVCHUcPqlKupy9xKhVVYdALXP0dW001w1n7F8a",
        MONTHLYPLAN: "price_1PTi7HFas3GhAJRiE6wlhc1L",
        YEARLYPLAN: "price_1PaxxeFas3GhAJRi5JhPac78",
        MONTHLYPLAN_PROFESSIONAL: "price_1PaxxUFas3GhAJRiSfZNXsjW",
        YEARLYPLAN_PROFESSIONAL: "price_1PaxxmFas3GhAJRil1bnFbBZ",
        TRIALPLAN: "price_1PaxwjFas3GhAJRiocA8j7Yj",
        SALESFORCE_CLIENT_ID:
          "3MVG9NnK0U_HimV7JrqZ8dW4pAfyPAtkdONq6kD37dmcka6VuntG9EqaJp3EJK0fLqDi9PNDQ4eA6btVUArUc",
        SALESFORCE_CLIENT_SECRET:
          "5671B0B7D4E76CA1613507CE5C690C875A902A47118C5BEB283840255BD0B255",
      };
    case "app":
      return {
        SERVER_API_URL: "http://localhost:4000",
        LIVEBOT_URL: "http://localhost:3001",
        PUBLIC_DOMAIN: "localhost",
        PAGE_DOMAIN: "localhost",
        PUBLIC_GOOGLE_APP_ID:
          "471909029023-13g22jeu5vbaosvrluu2nhe884aohf2b.apps.googleusercontent.com",
        PUBLIC_FACEBOOK_APP_ID: 2106755656362298,
        FACEBOOK_PAGE_ACCESS_TOKEN:
          "EAAQNduYKoLIBO9ZABP9XnZBfgXQZAPpg8jHOUxvzkXqZBzNTPMRcIX9IABSusOzUcnPsrQOach1JYWgYaZBBLbm4muFwVND0Ih6TBDgXZCtcbGkF29fdzk9k9aiHofRyYEAhs88fTGpf2lduWA8eqlN1ViJWu6exh0ZBxJM5ZCYr9IYLT7qt1JtxwUkptb59IO2an8RFzV42RPFqkGrEmpLe9JN43FUqf5HM9eEEEF7vxBkZD",
        FILESTACK_API_KEY: "AYTVLaHLRSs6sCRK6mNFcz",
        TIMEKIT_API_KEY: "test_widget_key_fjjvV6bPF8uCze6j3qJGMfbmBemvdPKf",
        TIMEKIT_PROJECT_ID: "d319cfec-7618-4765-abf5-fac8c9381192",
        isAI: false,
        META_API_VERSION: "v19.0",
        OUTLOOK_CLIENT_ID: "979c9342-67f3-4831-94f9-877f8013b90a",
        ENVIRONMENT: "production",
        MONTHLYPLAN: "price_1PUNgrAMQsTGGiHo3P1Nmb3A",
        YEARLYPLAN: "price_1PUNjlAMQsTGGiHomUfLjniP",
        TRIALPLAN: "price_1PUNnQAMQsTGGiHockGBkgWA",
        MONTHLYBRANDING: "price_1PUNvvAMQsTGGiHojYj1anlG",
        YEARLYBRANDING: "price_1PUNutAMQsTGGiHoxYfwJxBY",
        SALESFORCE_CLIENT_ID:
          "3MVG9NnK0U_HimV7JrqZ8dW4pAfyPAtkdONq6kD37dmcka6VuntG9EqaJp3EJK0fLqDi9PNDQ4eA6btVUArUc",
        SALESFORCE_CLIENT_SECRET:
          "5671B0B7D4E76CA1613507CE5C690C875A902A47118C5BEB283840255BD0B255",
      };
    default:
      return {
        SERVER_API_URL: "http://localhost:4000",
        PUBLIC_GOOGLE_APP_ID:
          "717509625034-05qjj1o7ulsr5rd5s0vbqgutg4752b5s.apps.googleusercontent.com", //prod
        PUBLIC_FACEBOOK_APP_ID: 2106755656362298,
        FACEBOOK_PAGE_ACCESS_TOKEN:
          "EAAQNduYKoLIBO9ZABP9XnZBfgXQZAPpg8jHOUxvzkXqZBzNTPMRcIX9IABSusOzUcnPsrQOach1JYWgYaZBBLbm4muFwVND0Ih6TBDgXZCtcbGkF29fdzk9k9aiHofRyYEAhs88fTGpf2lduWA8eqlN1ViJWu6exh0ZBxJM5ZCYr9IYLT7qt1JtxwUkptb59IO2an8RFzV42RPFqkGrEmpLe9JN43FUqf5HM9eEEEF7vxBkZD",
        FILESTACK_API_KEY: "A3ygIw4hISSCdApqW4SAwz",
        PUBLIC_DOMAIN: "app.engage.local",
        LIVEBOT_URL: "http://localhost:3001",
        TIMEKIT_API_KEY: "test_widget_key_fjjvV6bPF8uCze6j3qJGMfbmBemvdPKf",
        TIMEKIT_PROJECT_ID: "83d6c352-cd2a-4c84-8efe-12667c2a4d29",
        isAI: false,
        META_API_VERSION: "v19.0",
        OUTLOOK_CLIENT_ID: "979c9342-67f3-4831-94f9-877f8013b90a",
        ENVIRONMENT: "development",
        STRIPE_PUBLIC_KEY:
          "pk_test_51LDGaqAMQsTGGiHorKcAtUMskM5BOr2ycwMnLkpkOA2OprA618Q9ifCzblBI5bHzDymRuTQkmMDBGN09uKuFrCQs00p2zXDibJ",
        MONTHLYPLAN: "price_1PUNgrAMQsTGGiHo3P1Nmb3A",
        YEARLYPLAN: "price_1PUNjlAMQsTGGiHomUfLjniP",
        TRIALPLAN: "price_1PUNnQAMQsTGGiHockGBkgWA",
        MONTHLYBRANDING: "price_1PUNvvAMQsTGGiHojYj1anlG",
        YEARLYBRANDING: "price_1PUNutAMQsTGGiHoxYfwJxBY",
        SALESFORCE_CLIENT_ID:
          "3MVG9NnK0U_HimV7JrqZ8dW4pAfyPAtkdONq6kD37dmcka6VuntG9EqaJp3EJK0fLqDi9PNDQ4eA6btVUArUc",
        SALESFORCE_CLIENT_SECRET:
          "5671B0B7D4E76CA1613507CE5C690C875A902A47118C5BEB283840255BD0B255",
        // TEST_SERVER_API_URL_1: "https://proxy-s31z.onrender.com",
      };
  }
};

export default getConfig;
