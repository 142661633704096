import { Fragment, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { login, userSlice } from "../../../redux/User";
import environment from "../../../constants/Environment";

const environmentData = environment();

function ShowModal(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let modal: any = "";
  const getModal = () => {
    const [activeIdx, setActiveIdx] = useState<number>(0);

    const showmessage = ["raw", "format"];
    const copyToClipboard = async () => {
      let copyText: HTMLInputElement | null = document.getElementById(
        "error-text",
      ) as HTMLInputElement | null;

      try {
        if (copyText) {
          navigator.clipboard.writeText(copyText.innerText);
          dispatch(
            userSlice.actions.setToastPopUp({
              show: true,
              toastType: "success",
              message: "Copied to clipboard!",
            }),
          );
        }
      } catch (err) {
        console.log(err);
      }
    };
    useEffect(() => {
      let errmessage = props.message;
      if (!errmessage) return;
      let formattedString = "";
      if (errmessage?.startsWith('"') && errmessage?.endsWith('"')) {
        errmessage = errmessage?.substring(1, errmessage?.length - 1);
      }
      const keyValuePairs = errmessage?.split(",");
      keyValuePairs?.forEach((pair) => {
        const [key, value] = pair?.split(":");
        const trimmedKey = key?.trim();
        const trimmedValue = value?.trim();
        formattedString += `${trimmedKey}: ${trimmedValue}\n`;
      });

      const preElement = document.getElementById("error-text");
      if (preElement) {
        preElement.innerHTML = formattedString;
      }
    }, [activeIdx]);

    switch (props.modalType) {
      case "success":
        modal = (
          <div className="createbot-modal">
            <div className="craetebot-container">
              <div
                className={`success-cont p-relative ${props.modalButtonText ? "success-cont-downgrade" : ""} ${
                  window.screen.width < 1280 ? "success-cont-resp" : ""
                }`}
              >
                {!props.showModalButton && (
                  <div className="close flex-cnt" onClick={props.setModal}>
                    <svg
                      width="10"
                      height="10"
                      viewBox="0 0 10 10"
                      fill="#6D6D6D"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10 1.00714L8.99286 0L5 3.99286L1.00714 0L0 1.00714L3.99286 5L0 8.99286L1.00714 10L5 6.00714L8.99286 10L10 8.99286L6.00714 5L10 1.00714Z"
                        fill="#6D6D6D"
                      />
                    </svg>
                  </div>
                )}
                <div className="succ-header">
                  <img
                    src="https://res.cloudinary.com/dnag1wvx8/image/upload/v1663135241/success_icon_group_1_acgo4u.svg"
                    alt=""
                  />
                </div>
                <div className="succ-footer">
                  <h1>{props.modalTitle}</h1>
                  <span>{props.message}</span>
                 {props.showModalButton && (
                    <div className="flex-cnt gap-20">
                      {((props.calledFromAgent && !environmentData.isAI) ? (
                        <button
                          className={`${props.calledFromAgent ? "stay-btn" : ""}`}
                          onClick={() => props.setModal()}
                        >
                          Continue with chat
                        </button>)
                      : (
                        <button
                          onClick={() => {
                            props.setModal();
                            if (props.modalButtonText === "Go To Dashboard") {
                              navigate("/dashboard");
                            } else if (
                              props.modalButtonText === "Go To Billing"
                            ) {
                              navigate("/settings");
                              dispatch(userSlice.actions.setSettings(3));
                            }
                          }}
                        >
                          {props.modalButtonText ?? "Continue"}
                        </button>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        );
        break;

      case "error":
        modal = (
          <div className="createbot-modal">
            <div className="craetebot-container">
              <div className="success-cont">
                <div className="err-header">
                  <img
                    src="https://res.cloudinary.com/dnag1wvx8/image/upload/v1663138906/error_icon_group_ljebhg.svg"
                    alt=""
                  />
                </div>
                <div className="succ-footer">
                  <h1>{props.modalTitle}</h1>
                  <div className="err-ft-p">
                    <p>{props.message}</p>
                    {/* <p>Please select a first node before publishing.</p> */}
                  </div>
                  <button onClick={props.setModal}>Continue</button>
                </div>
              </div>
            </div>
          </div>
        );
        break;

      case "warning":
        modal = (
          <div className="createbot-modal">
            <div className="craetebot-container">
              <div className="success-cont">
                <div className="err-header">
                  {/* <img src="https://res.cloudinary.com/dnag1wvx8/image/upload/v1663138906/error_icon_group_ljebhg.svg" alt="" /> */}
                  <svg
                    width="132"
                    height="132"
                    viewBox="0 0 132 132"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="66"
                      cy="66"
                      r="66"
                      fill="white"
                      fillOpacity="0.2"
                    />
                    <circle
                      cx="66.0002"
                      cy="66.0002"
                      r="57.8947"
                      fill="white"
                      fillOpacity="0.36"
                    />
                    <rect
                      x="18.5254"
                      y="18.5264"
                      width="94.9474"
                      height="94.9474"
                      rx="47.4737"
                      fill="white"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M71.8864 44.2264C71.3535 43.1168 70.518 42.1802 69.4761 41.5248C68.4342 40.8693 67.2283 40.5215 65.9973 40.5215C64.7664 40.5215 63.5605 40.8693 62.5186 41.5248C61.4766 42.1802 60.6411 43.1168 60.1083 44.2264L41.3133 81.198C39.0647 85.6119 41.9062 91.4732 47.2001 91.4732H84.7923C90.0885 91.4732 92.9253 85.6142 90.6813 81.198L71.8864 44.2264ZM65.9973 56.7363C66.6115 56.7363 67.2005 56.9803 67.6348 57.4146C68.0691 57.8489 68.3131 58.4379 68.3131 59.0521V70.631C68.3131 71.2452 68.0691 71.8343 67.6348 72.2686C67.2005 72.7028 66.6115 72.9468 65.9973 72.9468C65.3831 72.9468 64.7941 72.7028 64.3598 72.2686C63.9255 71.8343 63.6815 71.2452 63.6815 70.631V59.0521C63.6815 58.4379 63.9255 57.8489 64.3598 57.4146C64.7941 56.9803 65.3831 56.7363 65.9973 56.7363V56.7363ZM65.9973 76.4205C66.6115 76.4205 67.2005 76.6645 67.6348 77.0988C68.0691 77.5331 68.3131 78.1221 68.3131 78.7363V79.8942C68.3131 80.5084 68.0691 81.0974 67.6348 81.5317C67.2005 81.966 66.6115 82.21 65.9973 82.21C65.3831 82.21 64.7941 81.966 64.3598 81.5317C63.9255 81.0974 63.6815 80.5084 63.6815 79.8942V78.7363C63.6815 78.1221 63.9255 77.5331 64.3598 77.0988C64.7941 76.6645 65.3831 76.4205 65.9973 76.4205V76.4205Z"
                      fill="#FB5F66"
                    />
                  </svg>
                </div>
                <div className="succ-footer">
                  <h1>{props.modalTitle}</h1>
                  <div className="err-ft-p">
                    <p>{props.message}</p>
                    {/* <p>Please select a first node before publishing.</p> */}
                  </div>
                  <button onClick={props.setModal}>Continue</button>
                </div>
              </div>
            </div>
          </div>
        );
        break;
      case "errorResponse":
        modal = (
          <div className="createbot-modal">
            <div className="craetebot-container">
              <div className="success-cont err-res">
                <div className="closing-modal">
                  <button onClick={props.setModal}>
                    <svg
                      width="10"
                      height="10"
                      viewBox="0 0 10 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10 1.00714L8.99286 0L5 3.99286L1.00714 0L0 1.00714L3.99286 5L0 8.99286L1.00714 10L5 6.00714L8.99286 10L10 8.99286L6.00714 5L10 1.00714Z"
                        fill="#6D6D6D"
                      ></path>
                    </svg>
                  </button>
                </div>
                <div className="modal-header">
                  <span className="err-logo">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="26"
                      height="25"
                      viewBox="0 0 26 25"
                      fill="none"
                    >
                      <g clipPath="url(#clip0_6742_772258)">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M15.8259 2.42447C15.5694 1.91096 15.1673 1.47757 14.6659 1.17424C14.1644 0.870901 13.5841 0.709961 12.9916 0.709961C12.3992 0.709961 11.8188 0.870901 11.3174 1.17424C10.8159 1.47757 10.4138 1.91096 10.1574 2.42447L1.11185 19.5336C0.0296448 21.5762 1.39717 24.2885 3.94499 24.2885H22.0371C24.5861 24.2885 25.9514 21.5772 24.8714 19.5336L15.8259 2.42447ZM12.9916 8.21359C13.2872 8.21359 13.5707 8.3265 13.7797 8.52748C13.9887 8.72845 14.1062 9.00103 14.1062 9.28525V14.6436C14.1062 14.9278 13.9887 15.2004 13.7797 15.4014C13.5707 15.6023 13.2872 15.7152 12.9916 15.7152C12.696 15.7152 12.4125 15.6023 12.2035 15.4014C11.9945 15.2004 11.8771 14.9278 11.8771 14.6436V9.28525C11.8771 9.00103 11.9945 8.72845 12.2035 8.52748C12.4125 8.3265 12.696 8.21359 12.9916 8.21359ZM12.9916 17.3227C13.2872 17.3227 13.5707 17.4356 13.7797 17.6366C13.9887 17.8376 14.1062 18.1102 14.1062 18.3944V18.9302C14.1062 19.2144 13.9887 19.487 13.7797 19.688C13.5707 19.889 13.2872 20.0019 12.9916 20.0019C12.696 20.0019 12.4125 19.889 12.2035 19.688C11.9945 19.487 11.8771 19.2144 11.8771 18.9302V18.3944C11.8771 18.1102 11.9945 17.8376 12.2035 17.6366C12.4125 17.4356 12.696 17.3227 12.9916 17.3227Z"
                          fill="#FB5F66"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_6742_772258">
                          <rect width="26" height="25" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </span>
                  <p>{props.modalTitle}</p>
                </div>
                <div className="succ-footer">
                  <div className="err-ft-p">
                    <div className="err-response-tabs">
                      <div
                        className={`inner-tabs ${activeIdx === 0 ? "active-tab" : ""}`}
                        onClick={() => setActiveIdx(0)}
                      >
                        Raw
                      </div>
                      <div
                        className={`inner-tabs ${activeIdx === 1 ? "active-tab" : ""}`}
                        onClick={() => setActiveIdx(1)}
                      >
                        Formatted
                      </div>
                    </div>
                    {showmessage.map((item, index) => {
                      return (
                        activeIdx === index && (
                          <div className="showmessage">
                            {item === "raw" && <p>{props.message}</p>}
                            {item === "format" && <pre id="error-text"> </pre>}
                          </div>
                        )
                      );
                    })}

                    <button onClick={copyToClipboard}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="16"
                        viewBox="0 0 14 16"
                        fill="none"
                      >
                        <path
                          d="M7.7 13.3333C8.62792 13.3323 9.51751 12.9808 10.1736 12.3559C10.8298 11.731 11.1989 10.8837 11.2 10V4.16201C11.2011 3.81158 11.1291 3.46444 10.9883 3.14069C10.8475 2.81694 10.6406 2.52304 10.3796 2.27601L8.8102 0.781345C8.55082 0.532794 8.24222 0.335747 7.90229 0.201625C7.56235 0.0675033 7.19785 -0.0010253 6.8299 1.15931e-05H3.5C2.57208 0.00107016 1.68249 0.3526 1.02635 0.977492C0.370217 1.60238 0.0011115 2.44961 0 3.33334V10C0.0011115 10.8837 0.370217 11.731 1.02635 12.3559C1.68249 12.9808 2.57208 13.3323 3.5 13.3333H7.7ZM1.4 10V3.33334C1.4 2.80291 1.62125 2.2942 2.01508 1.91913C2.4089 1.54406 2.94305 1.33334 3.5 1.33334C3.5 1.33334 6.9433 1.34268 7 1.34934V2.66668C7 3.0203 7.1475 3.35944 7.41005 3.60949C7.6726 3.85954 8.0287 4.00001 8.4 4.00001H9.7832C9.7902 4.05401 9.8 10 9.8 10C9.8 10.5304 9.57875 11.0392 9.18492 11.4142C8.7911 11.7893 8.25695 12 7.7 12H3.5C2.94305 12 2.4089 11.7893 2.01508 11.4142C1.62125 11.0392 1.4 10.5304 1.4 10ZM14 5.33334V12.6667C13.9989 13.5504 13.6298 14.3976 12.9736 15.0225C12.3175 15.6474 11.4279 15.999 10.5 16H4.2C4.01435 16 3.8363 15.9298 3.70503 15.8048C3.57375 15.6797 3.5 15.5102 3.5 15.3333C3.5 15.1565 3.57375 14.987 3.70503 14.8619C3.8363 14.7369 4.01435 14.6667 4.2 14.6667H10.5C11.057 14.6667 11.5911 14.456 11.9849 14.0809C12.3788 13.7058 12.6 13.1971 12.6 12.6667V5.33334C12.6 5.15653 12.6737 4.98696 12.805 4.86194C12.9363 4.73692 13.1143 4.66668 13.3 4.66668C13.4857 4.66668 13.6637 4.73692 13.795 4.86194C13.9263 4.98696 14 5.15653 14 5.33334Z"
                          fill="white"
                        />
                      </svg>
                    </button>
                    {/* <p>Please select a first node before publishing.</p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );

        break;
      default:
    }

    return modal;
  };

  return <Fragment>{getModal()}</Fragment>;
}
export default ShowModal;
