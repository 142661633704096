import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { State } from "../../../redux/Types";
import { userSlice } from "../../../redux/User";
import { axiosPost } from "../../../services/axiosService";

const ToastPopUp = (props) => {
  const dispatch = useDispatch();
  const [cancelLoader, setCancelLoader] = useState(false);
  const toastVisible = useSelector((state: State) => state.user.toast.show);
  const trainingBotId = useSelector((state: State) => state.user.trainingBotId);
  const trainingType = useSelector((state: State) => state.user.trainingType);

  useEffect(() => {
    const element = document.querySelector(
      ".ai-bot-container",
    ) as HTMLDivElement;
    if (element && window.screen.width <= 1280) {
      element.style.zIndex = props.toastType != "progress" ? "98" : "100";
    }
    return () => {
      if (element) {
        element.style.zIndex = "85";
      }
    };
  }, []);

  if (toastVisible === true) {
    setTimeout(() => {
      dispatch(
        userSlice.actions.setToastPopUp({
          show: false,
          toastType: "",
          message: "",
        }),
      );
    }, 4000);
  }

  async function cancelTraining() {
    try {
      if (trainingType === "document" || cancelLoader) return;
      setCancelLoader(true);

      await axiosPost("/event/terminate", { botCustomIdArr: [trainingBotId] });
    } catch (err) {
      setCancelLoader(false);
      console.log(err);
    }
  }

  return (
    <div onClick={(event) => event.stopPropagation()}>
      {props.toastType === "success" && (
        <div className="toast-popup">
          <div className="toast-popup-wrapper">
            <div className="toast-icon">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12Z"
                  fill="#4CAF50"
                />
                <path
                  d="M16.7959 7.17775C16.3622 6.8084 15.708 6.87548 15.3582 7.32517L10.75 13.25L8.49711 10.9971C8.09131 10.5913 7.42797 10.6096 7.04509 11.037L6.63145 11.4989C6.27723 11.8944 6.29383 12.4977 6.66925 12.8732L10.1983 16.4022C10.6236 16.8275 11.3254 16.7838 11.6947 16.309L17.4126 8.95823C17.7411 8.53589 17.679 7.92979 17.2717 7.58289L16.7959 7.17775Z"
                  fill="white"
                />
              </svg>
            </div>

            <div className="toast-content">
              {props.toastMessage === "Link has been deleted successfully." && (
                <h4>Delete Success</h4>
              )}
              {props.toastMessage}
            </div>
          </div>
        </div>
      )}
      {props.toastType === "error" && (
        <div className="toast-popup error-toast">
          <div className="toast-popup-wrapper">
            <div className="toast-icon">
              <svg
                width="22"
                height="23"
                viewBox="0 0 22 23"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M13.5408 2.09772C13.3108 1.61855 12.95 1.21415 12.5 0.931094C12.0501 0.648042 11.5294 0.497864 10.9978 0.497864C10.4663 0.497864 9.94556 0.648042 9.49565 0.931094C9.04573 1.21415 8.68494 1.61855 8.45484 2.09772L0.338846 18.0627C-0.632154 19.9687 0.594845 22.4997 2.88085 22.4997H19.1138C21.4008 22.4997 22.6258 19.9697 21.6568 18.0627L13.5408 2.09772ZM10.9978 7.49972C11.2631 7.49972 11.5174 7.60508 11.705 7.79261C11.8925 7.98015 11.9978 8.2345 11.9978 8.49972V13.4997C11.9978 13.7649 11.8925 14.0193 11.705 14.2068C11.5174 14.3944 11.2631 14.4997 10.9978 14.4997C10.7326 14.4997 10.4783 14.3944 10.2907 14.2068C10.1032 14.0193 9.99784 13.7649 9.99784 13.4997V8.49972C9.99784 8.2345 10.1032 7.98015 10.2907 7.79261C10.4783 7.60508 10.7326 7.49972 10.9978 7.49972ZM10.9978 15.9997C11.2631 15.9997 11.5174 16.1051 11.705 16.2926C11.8925 16.4801 11.9978 16.7345 11.9978 16.9997V17.4997C11.9978 17.7649 11.8925 18.0193 11.705 18.2068C11.5174 18.3944 11.2631 18.4997 10.9978 18.4997C10.7326 18.4997 10.4783 18.3944 10.2907 18.2068C10.1032 18.0193 9.99784 17.7649 9.99784 17.4997V16.9997C9.99784 16.7345 10.1032 16.4801 10.2907 16.2926C10.4783 16.1051 10.7326 15.9997 10.9978 15.9997Z"
                  fill="#FB5F66"
                />
              </svg>
            </div>
            <pre className="toast-content wb-ba">{props.toastMessage}</pre>
          </div>
        </div>
      )}
      {props.toastType === "progress" && (
        <>
          <div
            className={`toast-popup error-toast progress-toast ${
              props.trainBot && "trainBot-popup"
            }`}
          >
            <div className="progress-toast-content">
              <ProgressBar progress={props.progressStatus} />
              <div className="fx fx-d-col">
                <h4>
                  {props.trainBot
                    ? "Bot is getting trained!"
                    : "Please Wait..."}
                </h4>
                <p className="">{props.text ?? "Loading"}</p>
                {props.trainBot && (
                  <button
                    className={trainingType === "document" ? "disable" : ""}
                    onClick={cancelTraining}
                  >
                    {cancelLoader ? "Canceling..." : "Cancel training"}
                  </button>
                )}
              </div>
              {/* <div className="progress-status">{Math.round(props.progressStatus)}%</div> */}
            </div>
          </div>
          <div className="createbot-modal train-modal-container">
            <div className="train-modal p-relative">
              {/* <div className="close flex-cnt">
                <svg width="10" height="10" viewBox="0 0 10 10" fill="#6D6D6D" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M10 1.00714L8.99286 0L5 3.99286L1.00714 0L0 1.00714L3.99286 5L0 8.99286L1.00714 10L5 6.00714L8.99286 10L10 8.99286L6.00714 5L10 1.00714Z"
                    fill="#6D6D6D"
                  />
                </svg>
              </div> */}
              <div className="train-progress">
                <ProgressBar progress={props.progressStatus} />
                <div className="progress-bar-border"></div>
              </div>
              <div className="train-progress-content">
                <h2>
                  {props.trainBot
                    ? "Bot is getting trained!"
                    : "Please Wait..."}
                </h2>
                <p>
                  This may take a few seconds to minutes depending on the number
                  of links
                </p>
                <button
                  className={
                    props.trainBot
                      ? trainingType === "document"
                        ? "disable empty-small-modal-btn mt-20"
                        : "empty-small-modal-btn mt-20"
                      : "none"
                  }
                  onClick={cancelTraining}
                >
                  {cancelLoader ? "Canceling..." : "Cancel training"}
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ToastPopUp;

function ProgressBar({ progress }) {
  return (
    // <svg width={42} height={42} viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
    //   <g filter="url(#filter0_d_3445_10523)">
    //     <path
    //       d="M38.858 20.8895C38.858 30.7914 30.8309 38.8185 20.929 38.8185C11.0271 38.8185 3 30.7914 3 20.8895C3 10.9875 11.0271 2.96045 20.929 2.96045C30.8309 2.96045 38.858 10.9875 38.858 20.8895ZM8.3787 20.8895C8.3787 27.8208 13.9977 33.4398 20.929 33.4398C27.8604 33.4398 33.4793 27.8208 33.4793 20.8895C33.4793 13.9581 27.8604 8.33915 20.929 8.33915C13.9977 8.33915 8.3787 13.9581 8.3787 20.8895Z"
    //       fill="#EEEEEE"
    //     />
    //   </g>
    //   <path
    //     d="M34.3193 28.3871C35.6243 29.0963 36.1224 30.7449 35.2261 31.9293C33.5935 34.0868 31.4863 35.8565 29.0537 37.0931C25.8183 38.7378 22.1598 39.3619 18.562 38.8827C14.9643 38.4036 11.5967 36.8437 8.90446 34.4095C6.21225 31.9753 4.32224 28.7813 3.48432 25.2498C2.6464 21.7183 2.90004 18.0156 4.21169 14.6314C5.52334 11.2472 7.83124 8.34071 10.8302 6.29633C13.8292 4.25194 17.3781 3.16591 21.0076 3.18181C23.7363 3.19377 26.414 3.82817 28.8418 5.02224C30.1747 5.67776 30.4703 7.37432 29.623 8.5942C28.7757 9.81408 27.1052 10.0847 25.7331 9.51619C24.2349 8.89547 22.6226 8.56765 20.984 8.56046C18.4434 8.54933 15.9592 9.30955 13.8599 10.7406C11.7606 12.1717 10.145 14.2062 9.22689 16.5752C8.30873 18.9441 8.13119 21.536 8.71773 24.008C9.30427 26.4801 10.6273 28.7159 12.5118 30.4199C14.3964 32.1238 16.7537 33.2157 19.2721 33.5511C21.7906 33.8865 24.3515 33.4497 26.6163 32.2984C28.077 31.5558 29.3702 30.5386 30.4298 29.311C31.4003 28.1866 33.0143 27.678 34.3193 28.3871Z"
    //     fill="url(#paint0_linear_3445_10523)"
    //   />
    //   <defs>
    //     <filter
    //       id="filter0_d_3445_10523"
    //       x={0.34385}
    //       y={0.304299}
    //       width={41.1702}
    //       height={41.1702}
    //       filterUnits="userSpaceOnUse"
    //       colorInterpolationFilters="sRGB"
    //     >
    //       <feFlood floodOpacity={0} result="BackgroundImageFix" />
    //       <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
    //       <feMorphology radius={2.65615} operator="dilate" in="SourceAlpha" result="effect1_dropShadow_3445_10523" />
    //       <feOffset />
    //       <feComposite in2="hardAlpha" operator="out" />
    //       <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.02 0" />
    //       <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3445_10523" />
    //       <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3445_10523" result="shape" />
    //     </filter>
    //     <linearGradient id="paint0_linear_3445_10523" x1={3} y1={21.1107} x2={38.858} y2={21.1107} gradientUnits="userSpaceOnUse">
    //       <stop stopColor="#FB5F66" />
    //       <stop offset={1} stopColor="#FF8F94" />
    //     </linearGradient>
    //   </defs>
    // </svg>
    <svg
      role="progressbar"
      className="progress-bar-toast"
      width={42}
      height={42}
      viewBox="0 0 100 100"
      aria-valuemin={0}
      aria-valuemax={100}
      aria-valuenow={progress}
    >
      <circle
        cx="50%"
        cy="50%"
        r={42}
        shapeRendering="geometricPrecision"
        fill="none"
        stroke="#e6e6e6"
        strokeWidth={10}
      />
      {/* <defs>
        <linearGradient id="linear-4">
          <stop offset="0%" stopColor=" #FF8F94" />
          <stop offset="100%" stopColor="#FB5F66" />
        </linearGradient>
      </defs> */}
      <circle
        cx="50%"
        cy="50%"
        r={42}
        shapeRendering="geometricPrecision"
        className="pie-circle-4"
        fill="none"
        strokeWidth={12}
        strokeDashoffset={(264 * (100 - progress)) / 100}
        strokeDasharray={264}
        strokeLinecap="round"
        style={{
          transform: "rotate(-90deg)",
          transformOrigin: "50% 50%",
        }}
        stroke="#FB5F66"
        data-angel={75}
      />
      <text
        className="pie-text-4 progress-percent"
        x="50%"
        y="50%"
        fill="#000"
        textAnchor="middle"
        dy="0.35em"
        fontSize="16.2698px"
        fontWeight={500}
      >
        <tspan className="pie-percent-4">{Math.round(progress)}</tspan>
        <tspan className="pie-unit-4">{"%"}</tspan>
      </text>
    </svg>
  );
}
