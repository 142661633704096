import environment from "../constants/Environment";

const environmentData = environment();

export function setCookie(
  name,
  value,
  days = 7,
  domain = environmentData?.PUBLIC_DOMAIN,
  path = "/"
) {
  const expires = new Date(Date.now() + days * 864e5).toUTCString();
  document.cookie = `${name}=${encodeURIComponent(
    value
  )}; expires=${expires}; path=${path}; domain=${domain}`;
}

export function deleteCookies(name, path = "/") {
  const domain1 = environmentData?.PUBLIC_DOMAIN;
  const domain2 = environmentData?.PAGE_DOMAIN;
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=${path}; domain=${domain1}`;
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=${path}; domain=${domain2}`;
  // document.cookie = `zgiqcsrfcookie=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=thrive.zohopublic.com`;
  
}

export function getCookie(name: string) {
  return document.cookie.split("; ").reduce((r, v) => {
    const parts = v.split("=");
    return parts[0] === name ? decodeURIComponent(parts[1]) : r;
  }, "");
}
